<template>
  <v-card class="mx-auto" :width="$vuetify.breakpoint.xl ? '80%' : '95%'" tile>
    <v-tabs v-model="tab" background-color="secondary" fixed-tabs dark icons-and-text show-arrows
      active-class="secondary--text white">
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1"
        :class="`white--text text-none font-weight-medium ${$vuetify.breakpoint.lgAndDown ? 'text-body-2' : 'text-body-1'}`">
        Detalhes
        <v-icon v-text="'mdi-account-details'" :size="$vuetify.breakpoint.lgAndDown ? 20 : 24" />
      </v-tab>

      <v-tab href="#tab-2"
        :class="`white--text text-none font-weight-medium ${$vuetify.breakpoint.lgAndDown ? 'text-body-2' : 'text-body-1'}`">
        Social
        <v-icon v-text="'mdi-share-variant'" :size="$vuetify.breakpoint.lgAndDown ? 20 : 24" />
      </v-tab>

      <v-tab href="#tab-3"
        :class="`white--text text-none font-weight-medium ${$vuetify.breakpoint.lgAndDown ? 'text-body-2' : 'text-body-1'}`">
        Modelo
        <v-icon v-text="'mdi-card-account-details'" :size="$vuetify.breakpoint.lgAndDown ? 20 : 24" />
      </v-tab>

      <v-tab href="#tab-4"
        :class="`white--text text-none font-weight-medium ${$vuetify.breakpoint.lgAndDown ? 'text-body-2' : 'text-body-1'}`">
        Personalizar
        <v-icon v-text="'mdi-format-paint'" />
      </v-tab>

      <v-card tile elevation="0" min-width="150" target="_blank" link href="https://conectasuite.com/app/criarcontagratis/" class="sign-link d-flex flex-column align-center justify-center">
        <div class="d-flex align-center text-none font-weight-medium text-body-2 secondary--text">
          <v-icon v-text="'mdi-crown'" color="secondary" class="mr-2"
            :size="$vuetify.breakpoint.lgAndDown ? 18 : 24" />Aplicar
        </div>
        <div class="text-none description secondary--text mt-1">
          Teste 14 dias grátis
        </div>
      </v-card>
    </v-tabs>

    <v-tabs-items v-model="tab" class="overflow-y-auto"
      :style="`height: ${$vuetify.breakpoint.mobile ? '300px' : '100%'}`">
      <v-tab-item v-for="i in 4" :key="i" :value="'tab-' + i">
        <Details v-if="isDetailsTab" />
        <Social v-else-if="isSocialTab" />
        <Models v-else-if="isModelTab" />
        <Design v-else-if="isDesignTab" />
        <v-card v-else flat>
          <v-card-text>{{ text }}</v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import Details from "@/components/tabs/Details"
import Social from "@/components/tabs/Social"
import Models from "@/components/tabs/Models"
import Design from "@/components/tabs/Design"

export default {
  components: {
    Details, Social, Models, Design,
  },
  data() {
    return {
      tab: null,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }
  },
  computed: {
    isDetailsTab() {
      return this.tab === 'tab-1'
    },
    isSocialTab() {
      return this.tab === 'tab-2'
    },
    isModelTab() {
      return this.tab === 'tab-3'
    },
    isDesignTab() {
      return this.tab === 'tab-4'
    }
  }
}
</script>
<style scoped>
.sign-link {
  background: rgba(0, 0, 0, 0.699) !important
}

.description {
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 0px !important;
}
</style>