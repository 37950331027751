<template>
  <v-app class="overflow-y-hidden">
    <v-main class="main">
        <a href="https://conectasuite.com" target=”_blank”><v-img v-if="!isEmbed" :src="logo" contain :class="`mx-16 my-10`" width="260" /></a>
      <div v-if="showEditor" :style="`${isEmbed ? 'margin-top: 100px' : ''}`">
        <v-row :class="`${$vuetify.breakpoint.lgAndUp ? 'px-8' : ''} fill-height ma-0`">
          <v-col sm="12" md="11" lg="6">
            <v-card class="d-flex flex-column align-center px-0 transparent" flat>
              <Edit />
            </v-card>
          </v-col>
          <v-col sm="12" md="11" lg="6" :class="`d-flex flex-column align-center px-0`">
            <div :class="`pa-0 ma-0 d-flex flex-column align-center`" style="width: 100%;">
              <Preview class="align-self-center" />
              <v-btn rounded @click="(setShowInfoForm(true)), (setShowEditor(false))" v-if="$vuetify.breakpoint.mdAndUp"
                color="secondary" dark x-large class="mt-12">
                <v-icon v-text="'mdi-draw-pen'" class="mr-2" /> Gerar assinatura
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-btn rounded @click="(setShowInfoForm(true)), (setShowEditor(false))" v-if="$vuetify.breakpoint.smAndDown"
          color="secondary" dark x-large :fixed="$vuetify.breakpoint.mobile" :bottom="$vuetify.breakpoint.mobile"
          :right="$vuetify.breakpoint.mobile">
          <v-icon v-text="'mdi-draw-pen'" class="mr-2" /> Gerar assinatura
        </v-btn>

        <!-- <v-dialog v-model="showFinalSignature" max-width="800">
              <v-card class="pt-6 pb-8" tile>
                <v-card-title>
                  <v-img :src="logo" class="mx-auto" contain max-width="200" />
                </v-card-title>
                <v-card-subtitle class="text-center font-weight-bold text-h5 mt-5">
                  Feito! Sua assinatura foi copiada com sucesso. 💜
                </v-card-subtitle>
                <v-card-text class="text-center text-body-1">
                  Agora, para aplicá-la, basta adicionar nas configurações do seu e-mail.
                </v-card-text>
                <v-card-actions class="my-4">
                  <v-card v-if="switchTemplate == 'C00'" outlined tile width="100%"
                    class="pa-2 mx-4 mb-5 overflow-x-auto overflow-y-hidden">
                    <Model00 />
                  </v-card>
                  <v-card v-if="switchTemplate == 'C01'" outlined tile width="100%"
                    class="pa-2 mx-4 mb-5 overflow-x-auto overflow-y-hidden">
                    <Model01 />
                  </v-card>
                  <v-card v-if="switchTemplate == 'C03'" outlined tile width="100%"
                    class="pa-2 mx-4 mb-5 overflow-x-auto overflow-y-hidden">
                    <Model03 />
                  </v-card>
                  <v-card v-if="switchTemplate == 'C07'" outlined tile width="100%"
                    class="pa-2 mx-4 mb-5 overflow-x-auto overflow-y-hidden">
                    <Model07 />
                  </v-card>
                </v-card-actions>
                <v-card-actions class="d-flex align-center justify-center">
                  <v-btn @click="copy()" tile color="secondary" dark x-large>
                    <v-icon v-text="'mdi-content-copy'" class="mr-4" /> Copiar assinatura
                  </v-btn>
                  <v-btn href="https://conectasuite.com/app/criarcontagratis/" target="_blank" tile color="primary" dark
                    x-large>
                    <v-icon v-text="'mdi-crown'" class="mr-4" size="32" color="white" />
                    <div>
                      <div class="font-weight-bold text-none">Aplicar assinatura em massa</div>
                      <div class="caption text-none">Desbloqueie essa e outras funcionalidades com 14 dias grátis</div>
                    </div>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog> -->
      </div>

      <FormInfo v-if="showInfoForm" />

      <SuccessView v-if="showFinalSignature" />
    </v-main>
  </v-app>
</template>

<script>
import Preview from "@/components/Preview"
import FormInfo from "@/components/FormInfo"
import SuccessView from "@/components/SuccessView"
import Edit from "@/components/Edit"
import { mapState, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'App',
  components: {
    Preview, Edit, FormInfo, SuccessView,
  },
  data: () => ({
    logo: require("@/assets/images/logo.png"),
  }),
  computed: {
    ...mapState(["switchTemplate"]),
    ...mapGetters([
      "showEditor",
      "showInfoForm",
      "showFinalSignature",
      "showSuccessCopy",
      "isEmbed"])
  },
  methods: {

    ...mapMutations([
      "setShowEditor",
      "setShowInfoForm",
      "setShowFinalSignature",
      "setShowSuccessCopy",
      "setIsEmbed",
      "setSwitchTemplate",
      "setTitle",
      "setTitleFontSize",
      "setTitleColor",
      "setText",
      "setTextFontSize",
      "setTextColor",
      "setCorRedesSociais",
    ]),
  },
    mounted() {
    const url = window.location.href;
    const urlParams = new URLSearchParams(url);

    const isEmbed = urlParams.get('isEmbed') === 'true';
    this.setIsEmbed(isEmbed)

    const selectedModel = urlParams.get('selectedModel');
    if (selectedModel) {
      this.setSwitchTemplate(selectedModel);
    }

    const titleFont = urlParams.get('titleFont');
    if (titleFont) {
      this.setTitle(titleFont);
    }

    const titleFontSize = urlParams.get('titleFontSize');
    if (titleFontSize) {
      this.setTitleFontSize(titleFontSize);
    }

    const titleColor = urlParams.get('titleColor');
    if (titleColor) {
      this.setTitleColor(titleColor);
    }

    const textFont = urlParams.get('textFont');
    if (textFont) {
      this.setText(textFont);
    }

    const textFontSize = urlParams.get('textFontSize');
    if (textFontSize) {
      this.setTextFontSize(textFontSize);
    }

    const textColor = urlParams.get('textColor');
    if (textColor) {
      this.setTextColor(textColor);
    }

    const socialColor = urlParams.get('socialColor');
    if (socialColor) {
      this.setCorRedesSociais(socialColor);
    }
  },
};
</script>
<style scoped>
.main {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background: #311F50;
}
</style>
